<template>
  <div class="reset-password">
    <AuthHeader :class="{ active: isEmailSent }">
      <span class="reset__text">
        {{ $t('login.alreadyRegistered') }}
      </span>
      <AstroButton
        class="reset__login"
        :title="$t('login.enter')"
        @click="$router.replace({ name: 'signIn' })"
        color="secondary"
      />
    </AuthHeader>
    <AuthContent>
      <div v-if="!isEmailSent" class="reset-password__content auth-content__wrapper">
        <h2 class="reset-password__title form_title">{{ $t('login.resetPassword') }}</h2>
        <p class="reset-password__text">
          {{ $t('login.resetPasswordEmail') }}
        </p>
        <v-form ref="form" class="reset-password__form" v-model="isFormValid" lazy-validation @submit="onSubmit">
          <TextField
            class="reset-password__email"
            label="E-mail"
            id="email"
            autocomplete="on"
            name="email"
            v-model="email"
            :rules="[rules.required, rules.isEmail]"
          />
          <div class="reset-password__controls">
            <AstroButton
              class="reset-password__cancel"
              type="button"
              color="secondary"
              :title="$t('login.cancel')"
              @click="$router.push({ name: 'signIn' })"
            />
            <AstroButton
              class="reset-password__submit"
              type="submit"
              :title="$t('login.submit')"
              :disabled="!isFormValid"
              :loading="submitInProgress"
            />
          </div>
          <p class="reset-password__errors" v-if="error">
            {{ error }}
          </p>
        </v-form>
      </div>
      <div v-if="isEmailSent" class="reset-password__banner">
        <img class="reset-password__banner-sputnik" src="@/assets/auth/sputnik-banner-v2.svg" alt="sputnik" />
        <p class="reset-password__banner-text">
          {{ $t('login.ifRegistered') }}
        </p>
        <p class="reset-password__banner-text">
          {{ $t('login.checkEmail') }}
        </p>
      </div>
    </AuthContent>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import AuthHeader from '../components/AuthHeader';
import TextField from '../components/TextField';
import AuthContent from '../components/AuthContent';
import AstroButton from '../components/AstroButton';
import { UNDEFINED_ERROR_MESSAGE } from '@/constants';

export default {
  name: 'ResetPassword',
  components: { TextField, AstroButton, AuthHeader, AuthContent },
  data() {
    return {
      isEmailSent: false,
      isFormValid: true,
      email: null,
      error: null,
      submitInProgress: false,
      /*rules: {
        required: (value) => !!value || 'Поле должно быть заполнено',
        isEmail: (value) => /.+@.+/.test(value) || 'Неверный формат E-mail',
      },*/
    };
  },

  computed: {
    rules() {
      return {
        required: (value) =>
          !!value ||
          (this.$i18n.locale === 'en' ? 'This field should be filled in' : 'Das Feld muss ausgefüllt werden'),
        isEmail: (value) =>
          /.+@.+/.test(value) || (this.$i18n.locale === 'en' ? 'Invalid email format' : 'Ungültiges Email-Format'),
      };
    },
  },

  methods: {
    ...mapActions('UserModule', ['resetPassword']),
    async onSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.error = null;

      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      try {
        this.submitInProgress = true;
        await this.resetPassword(this.email);
        this.isEmailSent = true;
      } catch (error) {
        if (error && error.status === 400) {
          this.error = this.$i18n.t('errors.notFoundEmail');
        } else {
          this.error = (error && error.data.message) || UNDEFINED_ERROR_MESSAGE;
        }
      } finally {
        this.submitInProgress = false;
      }
    },
  },
};
</script>

<style scoped lang="sass">
.reset
  &__login
    @media screen and (max-width: base-unit(1024))
      display: none
  &__text
    margin-right: base-unit(10)
    color: grey-color('75')
    +base-font-size
    font-weight: 400
    @media screen and (max-width: base-unit(1024))
      display: none

.reset-password
  display: flex
  flex-direction: column

  .auth-header
    +extra-small-devices
      display: none
    &.active
      +extra-small-devices
        display: block

  &__content
    margin-top: base-unit(64)
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 4px
    padding: base-unit(30) base-unit(40) base-unit(40)
    border: 1px solid #F8F8F8
    +extra-small-devices
      margin-top: base-unit(130)
      padding: 0
      border: none
      width: 100%
    .form_title
      +extra-small-devices
        display: block
        font-size: base-unit(18)
        line-height: base-unit(21)
        margin-bottom: base-unit(30)
        font-weight: 700
        color: blue-color('primary')

  &__title
    font-size: base-unit(18)
    line-height: base-unit(21)
    margin: 0
    font-weight: bold
    color: blue-color('primary')

  &__text
    font-size: base-unit(16)
    line-height: base-unit(19)
    color: grey-color('500')
    text-align: left
    max-width: base-unit(280)
    margin: base-unit(25) 0 0 0
    +extra-small-devices
      max-width: 100%
      margin-top: 0

  &__form
    display: flex
    flex-direction: column
    align-items: center
    margin-top: base-unit(20)
    width: 100%

  &__email::v-deep
    width: 100%
    background-color: transparent
    input
      background: transparent
      width: 100%
      &:-webkit-autofill
        transition: background-color 600000s 0s, color 600000s 0s
      &:-webkit-autofill:focus
        transition: background-color 600000s 0s, color 600000s 0s
    &.loading
      fieldset
        background-color: grey-color('10')
        border: 1px solid grey-color('50')
    .error--text
      fieldset
        border: 1px solid #B00020
    .v-input__slot
      margin-bottom: base-unit(2)
      padding-left: base-unit(16) !important


  &__controls
    margin-top: base-unit(14)
    display: flex
    justify-content: space-between
    width: 100%

  &__cancel
    width: 100%
    max-width: base-unit(142)

  &__submit
    margin-left: base-unit(9)
    width: 100%
    max-width: base-unit(142)
    ::v-deep .loading
      +small-devices
        left: -1 * base-unit(17)
        width: 15px !important


  &__banner
    display: flex
    row-gap: base-unit(10)
    flex-direction: column
    align-items: center
    margin-top: 5vh

  &__banner-sputnik
    width: 100%
    max-width: base-unit(666)

  &__banner-text
    margin-bottom: 0
    text-align: center
    font-size: base-unit(16)
    width: 100%
    max-width: base-unit(350)
    font-weight: 400
    color: grey-color('500')

  &__errors
    +form-errors-text
</style>
