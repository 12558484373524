<template>
  <a
    class="google-authorization-link"
    :class="{ 'google-authorization-link--disabled': disabled }"
    :href="authorizationLink"
  >
    <img
      class="google-authorization-link__logo"
      :src="require('@/assets/auth/google.svg')"
      width="16"
      height="16"
      alt="google logo"
    />
    <span class="google-authorization-link__text">{{ $t('google') }}</span>
  </a>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    authorizationLink() {
      return `${process.env.VUE_APP_API_URL}/authorization/google`;
    },
  },
};
</script>

<style lang="sass">
.google-authorization-link
  display: flex
  align-items: center
  justify-content: flex-start
  width: base-unit(280)
  height: base-unit(36)
  padding: 0 base-unit(12)
  text-decoration: none
  border: 1px solid grey-color('75')
  border-radius: 4px

  +extra-small-devices
    width: 100%

  &--disabled
    pointer-events: none

    .google-authorization-link__logo
      opacity: 0.5

    .google-authorization-link__text
      color: grey-color('75')

  &__logo
    width: base-unit(16)
    height: base-unit(16)

  &__text
    margin-left: base-unit(12)
    color: grey-color('500')
</style>
