<template>
  <div class="update-password">
    <AuthHeader :class="{ active: isSuccess }">
      <span class="update-password__text">
        {{ $t('login.alreadyRegistered') }}
      </span>
      <AstroButton :title="$t('login.enter')" @click="$router.replace({ name: 'signIn' })" />
    </AuthHeader>
    <AuthContent>
      <div v-if="!isSuccess" class="update-password__content auth-content__wrapper">
        <h2 class="update-password__title form_title">{{ $t('login.newPassword') }}</h2>
        <v-form ref="form" class="update-password__form" v-model="isFormValid" lazy-validation @submit="onSubmit">
          <PasswordField
            class="update-password__password"
            :label="$t('login.password')"
            v-model="password"
            :rules="[rules.required]"
          />
          <PasswordField
            class="update-password__password-confirm"
            :label="$t('login.repeatPassword')"
            v-model="confirmPassword"
            :rules="[rules.required, rules.isEqual]"
          />
          <AstroButton class="update-password__submit" type="submit" :title="$t('save')" :disabled="!isFormValid" />
          <p class="update-password__errors" v-if="error">
            {{ error }}
          </p>
        </v-form>
      </div>
      <div v-if="isSuccess" class="update-password__banner">
        <img class="update-password__banner-success" src="@/assets/auth/success-banner-v2.svg" alt="banner" />
        <p class="update-password__banner-text">
          {{ $t('login.refreshPassword') }}
        </p>
        <AstroButton :title="$t('login.enter')" @click="$router.replace({ name: 'signIn' })" />
      </div>
    </AuthContent>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import AuthHeader from '../components/AuthHeader';
import AuthContent from '../components/AuthContent';
import AstroButton from '../components/AstroButton';
import PasswordField from '../components/PasswordField';
import { UNDEFINED_ERROR_MESSAGE } from '@/constants';

export default {
  name: 'UpdatePassword',
  components: { AstroButton, PasswordField, AuthHeader, AuthContent },
  data() {
    return {
      isSuccess: false,
      isFormValid: true,
      password: null,
      confirmPassword: null,
      error: null,
      /*rules: {
        required: (value) =>
          !!value ||
          (this.$i18n.locale === 'en' ? 'This field should be filled in' : 'Das Feld muss ausgefüllt werden'),
        isEqual: (value) => value === this.password || 'Пароль отличается',
      },*/
    };
  },

  computed: {
    rules() {
      return {
        required: (value) =>
          !!value ||
          (this.$i18n.locale === 'en' ? 'This field should be filled in' : 'Das Feld muss ausgefüllt werden'),
        isEqual: (value) => value === this.password || this.$i18n.t('rules.different'),
      };
    },
  },

  methods: {
    ...mapActions('UserModule', ['updatePassword']),
    async onSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.error = null;

      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      try {
        await this.updatePassword({
          updatedPassword: this.password,
          resetToken: this.$route.query.token,
        });
        this.isSuccess = true;
      } catch (error) {
        this.error = (error && error.data.message) || UNDEFINED_ERROR_MESSAGE;
      }
    },
  },
  created() {
    this.$i18n.locale = this.$route.query.locale;
  },
};
</script>

<style scoped lang="sass">
.update-password
  display: flex
  flex-direction: column
  &__text
    margin-right: base-unit(10)
    color: grey-color('75')
    +base-font-size
    font-weight: 400
    @media screen and (max-width: base-unit(1024))
      display: none

  .auth-header
    +extra-small-devices
      display: none
    &.active
      display: flex
      button
        +extra-small-devices
          display: none
    button
      background-color: transparent
      color: blue-color('primary')
      &:hover
        border-color: blue-color('lighter')
        color: blue-color('lighter')
        background-color: transparent

  &__content
    flex-direction: column
    border-radius: 4px
    padding: base-unit(30) base-unit(40) base-unit(40)
    border: 1px solid #F8F8F8
    margin-top: base-unit(64)
    +extra-small-devices
      padding: 0
      border: none
      width: 100%
      margin-top: base-unit(130)
    .form_title
      +extra-small-devices
        display: block
        text-align: center
        font-size: base-unit(18)
        line-height: base-unit(21)
        margin-bottom: base-unit(30)
        font-weight: 700
        color: blue-color('primary')

  &__title
    font-size: base-unit(30)
    margin: 0
    font-weight: 400
    color: blue-color('primary')
    +extra-small-devices
      font-size: base-unit(18)

  &__form
    display: flex
    flex-direction: column
    width: base-unit(280)
    +extra-small-devices
      width: 100%

  &__password::v-deep
    &.loading
      .v-icon
        display: none
      fieldset
        background-color: grey-color('10')
        border: 1px solid grey-color('50')
    .error--text
      fieldset
        border: 1px solid #B00020
    .v-input__slot
      margin-bottom: base-unit(2)
      padding-left: base-unit(16) !important
    .v-icon
      font-size: base-unit(16)
      color: grey-color('75')

  &__password-confirm
    margin-top: base-unit(10)
    &::v-deep
      &.loading
        .v-icon
          display: none
        fieldset
          background-color: grey-color('10')
          border: 1px solid grey-color('50')
      .error--text
        fieldset
          border: 1px solid #B00020
      .v-input__slot
        margin-bottom: base-unit(2)
        padding-left: base-unit(16) !important
      .v-icon
        font-size: base-unit(16)
        color: grey-color('75')

  &__submit
    margin-top: base-unit(24)
    width: 100%

  &__banner
    display: flex
    flex-direction: column
    width: 100%
    align-items: center
    margin-top: base-unit(123)
    +extra-small-devices
      margin-top: base-unit(50)
    button
      display: none
      +extra-small-devices
        width: 100%
        display: flex

    &-success
      width: 100%
      max-width: base-unit(300)

    &-text
      margin-top: base-unit(40)
      text-align: center
      font-size: base-unit(16)
      width: 100%
      font-weight: 400
      color: grey-color('500')

  &__errors
    +form-errors-text
</style>
