<template>
  <div class="sign-up">
    <AuthHeader>
      <span class="sign-up__text">
        {{ $t('login.alreadyRegistered') }}
      </span>
      <AstroButton
        class="sign-up__login"
        :title="$t('login.enter')"
        @click="$router.replace({ name: 'signIn' })"
        color="secondary"
      />
    </AuthHeader>
    <AuthContent>
      <div class="auth-content__wrapper sign-up__wrapper">
        <div class="sign-up__form-wrapper" :class="{ 'border-none': isEmailSent }">
          <h2 v-if="!isEmailSent" class="form_title">{{ $t('login.reginstration') }}</h2>
          <v-form
            v-if="!isEmailSent"
            ref="form"
            class="sign-up__form"
            v-model="isFormValid"
            @submit="onSubmit"
            lazy-validation
          >
            <TextField
              :class="{ loading: submitInProgress }"
              id="email"
              label="E-mail"
              autocomplete="on"
              :disabled="submitInProgress"
              name="email"
              v-model="email"
              :rules="[rules.requiredEmail, rules.isEmail]"
            />
            <PasswordField
              class="sign-up__password"
              :class="{ loading: submitInProgress }"
              :disabled="submitInProgress"
              :label="$t('login.password')"
              v-model="password"
              :rules="[rules.requiredPassword]"
            />
            <CheckBox :disabled="submitInProgress" v-model="isAgreeWithRules" :rules="[rules.required]">
              <div>
                <span>{{ $t('privatPolicy1') }} </span>
                <a target="_blank" :href="privacyPolicyLink" @click.stop>
                  <span>{{ $t('privatPolicy2') }}</span>
                </a>
              </div>
            </CheckBox>
            <AstroButton
              class="sign-up__submit"
              :class="{ load: submitInProgress }"
              type="submit"
              :title="$t('login.signUp')"
              :disabled="!isFormValid"
              :loading="submitInProgress"
            />
            <div class="sign-up__mobile">
              <span>{{ $t('login.alreadyRegistered') }}</span>
              <AstroButton :title="$t('login.enter')" @click="$router.replace({ name: 'signIn' })" color="secondary" />
            </div>
            <p class="sign-up__errors" v-if="error">
              {{ error }}
            </p>
          </v-form>
          <div v-if="isEmailSent" class="sign-up__banner">
            <img class="sign-up__banner-sputnik" src="@/assets/auth/sputnik-banner-v2.svg" alt="sputnik" />
            <p class="sign-up__banner-text">
              {{ $t('login.emailSent') }}
              <span class="sign-up__banner-text_accent">{{ email }}</span>
            </p>
            <p class="sign-up__banner-text">
              {{ $t('login.checkEmail') }}
            </p>
          </div>
          <div v-if="!isEmailSent" class="sign-up__separator">{{ $t('or') }}</div>
          <google-authorization-link class="sign-up__google" v-if="!isEmailSent" :disabled="submitInProgress" />
        </div>
        <!--        <div v-if="!isEmailSent" class="sign-up__features">
          <div class="sign-up__features-item">
            <h6 class="sign-up__features-item&#45;&#45;title sign-up__features-item&#45;&#45;yellow">2 недели Премиума</h6>
            <span class="sign-up__features-item&#45;&#45;desc">после регистрации</span>
            <span class="sign-up__features-item&#45;&#45;info">
              390 ₽/мес
              <span>Бесплатно</span>
            </span>
          </div>
          <div class="sign-up__features-item">
            <h6 class="sign-up__features-item&#45;&#45;title sign-up__features-item&#45;&#45;pink">Премиум-функции</h6>
            <ul class="sign-up__features-list">
              <li class="sign-up__features-list&#45;&#45;item" v-for="(feature, index) in features" :key="index">
                {{ feature }}
              </li>
            </ul>
          </div>
          <div class="sign-up__features-item">
            <h6 class="sign-up__features-item&#45;&#45;title sign-up__features-item&#45;&#45;purple">5000+ астрологов</h6>
            <span class="sign-up__features-item&#45;&#45;desc">уже пользуются Астроновой</span>
          </div>
        </div>-->
      </div>
      <div v-if="!isEmailSent" class="auth-stars">
        <img class="auth-stars__img" src="../../assets/auth/stars.svg" alt="stars" />
      </div>
    </AuthContent>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import AuthHeader from '../components/AuthHeader';
import AuthContent from '../components/AuthContent';
import AstroButton from '../components/AstroButton';
import GoogleAuthorizationLink from '../components/GoogleAuthorizationLink';
import TextField from '../components/TextField';
import PasswordField from '../components/PasswordField';
import CheckBox from '@/elements/components/check-box';
import { UNDEFINED_ERROR_MESSAGE } from '@/constants';

export default {
  name: 'SignUp',
  components: {
    CheckBox,
    PasswordField,
    TextField,
    AstroButton,
    GoogleAuthorizationLink,
    AuthHeader,
    AuthContent,
  },
  data() {
    return {
      isFormValid: true,
      isEmailSent: false,
      submitInProgress: false,
      isAgreeWithRules: false,
      email: null,
      password: null,
      error: null,
      /*      rules: {
        required: (value) =>
          !!value || (i18n.locale === 'ru' ? 'Поле должно быть заполнено' : 'Das Feld muss ausgefüllt werden'),
        requiredEmail: (value) => !!value || 'Введите e-mail',
        requiredPassword: (value) => !!value || 'Введите пароль',
        isEmail: (value) => /.+@.+/.test(value) || 'Неверный формат E-mail',
      },*/
      privacyPolicyLink: process.env.VUE_APP_PRIVACY_POLICY_LINK,
      features: [
        'Полная интерпретация планет\n' + 'в знаках и домах',
        'Цепочки планет',
        'Выбор метода расчета орбисов',
        'Интерпретация аспектов',
        'Другие полезные функции',
      ],
    };
  },
  computed: {
    rules() {
      return {
        required: (value) =>
          !!value ||
          (this.$i18n.locale === 'en' ? 'This field should be filled in' : 'Das Feld muss ausgefüllt werden'),
        requiredEmail: (value) => !!value || (this.$i18n.locale === 'en' ? 'Enter e-mail' : 'E-mail eingeben'),
        requiredPassword: (value) => !!value || (this.$i18n.locale === 'en' ? 'Enter password' : 'Passwort eingeben'),
        isEmail: (value) =>
          /.+@.+/.test(value) || (this.$i18n.locale === 'en' ? 'Invalid email format' : 'Ungültiges Email-Format'),
      };
    },
  },
  methods: {
    ...mapActions('UserModule', ['signUp']),
    async onSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.error = null;

      await this.$refs.form.validate();
      if (!this.isFormValid) return;
      this.submitInProgress = true;
      const signUpData = {
        email: this.email,
        password: this.password,
      };
      try {
        await this.signUp(signUpData);
        this.isEmailSent = true;
      } catch (error) {
        this.submitInProgress = false;
        if (error && error.status === 400) {
          if (this.$i18n.locale === 'en') {
            this.error = 'This email address has already been registered';
          } else {
            this.error = 'Es gibt bereits einen Nutzer mit dieser E-Mail-Adresse';
          }
        } else {
          this.error = (error && error.data.message) || UNDEFINED_ERROR_MESSAGE;
        }
      }
    },
  },
};
</script>

<style scoped lang="sass">
.sign-up
  display: flex
  height: 100%
  flex-direction: column
  &__form-wrapper
    border-radius: 4px
    padding: base-unit(30) base-unit(40) base-unit(40)
    border: 1px solid #F8F8F8
    +extra-small-devices
      padding: 0
      border: none
      width: 100%
  &__features
    background-color: #F8F8F8
    border: 1px solid #F8F8F8
    display: flex
    flex-direction: column
    border-radius: 4px
    padding: base-unit(30) base-unit(40) base-unit(40)
    +extra-small-devices
      width: 100%
      padding: base-unit(30) base-unit(20)
    &-list
      display: flex
      flex-direction: column
      width: 100%
      max-width: base-unit(260)
      padding-left: base-unit(17)
      list-style: none
      &--item
        color: grey-color('500')
        list-style-image: url('../../assets/auth/list-star.svg')
      &--item:not(:last-child)
        margin-bottom: base-unit(10)

    &-item
      display: flex
      flex-direction: column
      +base-font-size
      &--yellow
        background: linear-gradient(90deg, #47A1EE 0%, #EDCE80 100%)
      &--pink
        background: linear-gradient(90deg, #F882C7 0%, #F2C94C 100%)
      &--purple
        background: linear-gradient(90deg, #8295EB 0%, #F776B3 100%)
      &--title
        font-size: base-unit(32)
        font-weight: 700
        line-height: base-unit(38)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        margin-bottom: base-unit(5)
        +extra-small-devices
          font-size: base-unit(26)
      &--desc
        color: grey-color('500')
      &--info
        color: grey-color('75')
        span
          margin-left: base-unit(10)
          color: blue-color('primary')
    &-item:not(:last-child)
      margin-bottom: base-unit(30)
      .sign-up__features-item--desc
        margin-bottom: base-unit(10)
  &__wrapper
    display: flex
    +extra-small-devices
      row-gap: base-unit(40)
  &__mobile
    display: flex
    justify-content: center
    align-items: center
    margin-top: base-unit(10)

    @media screen and (min-width: base-unit(1024))
      display: none

    button
      border: none
      font-size: base-unit(16)
      padding-left: base-unit(5)


  &__form
    width: base-unit(280)
    +extra-small-devices
      width: 100%

  &__submit
    width: 100%
    &.load
      background-color: #73B2C7
      border: none

  &__banner
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 5vh

  &__banner-sputnik
    width: 100%
    max-width: base-unit(666)

  &__banner-text
    margin: base-unit(16) 0 0 0
    font-size: base-unit(18)
    font-weight: 400
    color: grey-color('500')
    +extra-small-devices
      text-align: center

    &_accent
      font-weight: 500

  &__login
    display: block
    +extra-small-devices
      font-size: base-unit(14)
      min-width: base-unit(100)
    @media screen and (max-width: base-unit(1024))
      display: none

  &__errors
    +form-errors-text

  &__separator
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: base-unit(280)
    margin-top: base-unit(20)
    color: grey-color('500')
    +extra-small-devices
      margin: base-unit(20) auto

    &::before,
    &::after
      content: ''
      position: absolute
      width: base-unit(110)
      height: base-unit(1)
      background-color: grey-color('75')

    &::before
      left: 0

    &::after
      right: 0

  &__google
    margin-top: base-unit(20)
    +extra-small-devices
      margin: 0 auto

  &__text
    margin-right: base-unit(10)
    color: grey-color('75')
    +base-font-size
    font-weight: 400
    @media screen and (max-width: base-unit(1024))
      display: none
  &__password::v-deep
    &.loading
      .v-icon
        display: none
      fieldset
        background-color: grey-color('10')
        border: 1px solid grey-color('50')
    .error--text
      fieldset
        border: 1px solid #B00020
    .v-input__slot
      margin-bottom: base-unit(2)
      padding-left: base-unit(16) !important
    .v-icon
      font-size: base-unit(16)
      color: grey-color('75')

#email::v-deep
  input
    background: transparent
    width: 100%
    &:-webkit-autofill
      transition: background-color 600000s 0s, color 600000s 0s
    &:-webkit-autofill:focus
      transition: background-color 600000s 0s, color 600000s 0s
  &.loading
    fieldset
      background-color: grey-color('10')
      border: 1px solid grey-color('50')
  .error--text
    fieldset
      border: 1px solid #B00020
  .v-input__slot
    margin-bottom: base-unit(2)
    padding-left: base-unit(16) !important
.border-none
  border: none
</style>
