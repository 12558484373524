<template>
  <header class="auth-header">
    <router-link to="/" class="auth-header__logo">
      <img class="logo-img" width="30" :src="require('@/assets/logo/LogoCollapsed-blue.svg')" alt="logo" />
      <img class="logo-text" width="110" :src="require('@/assets/logo/LogoTextEu-blue.svg')" alt="logo" />
    </router-link>
    <slot />
  </header>
</template>

<script>
export default {
  name: 'AuthHeader',
};
</script>

<style scoped lang="sass">
.auth-header
  display: flex
  align-items: center
  justify-content: center
  padding: base-unit(30)
  box-sizing: border-box
  +extra-small-devices
    padding: base-unit(30) 0

  @media (max-width: $screen-md-min)
    padding: base-unit(80) 8% 2%
    margin: 0 auto
  +extra-small-devices
    padding: base-unit(30) 0

  &__logo
    margin-right: auto
    padding-right: base-unit(20)
.logo-img
  margin-bottom: base-unit(-7)

.logo-text
  margin-left: base-unit(12)
  transition: 0.3s
</style>
