<template>
  <div class="sign-in">
    <AuthHeader>
      <span class="sign-in__text">
        {{ $t('login.dontHaveAccount') }}
      </span>
      <AstroButton
        class="sign-in__register"
        :title="$t('login.signUp')"
        @click="$router.replace({ name: 'signUp' })"
        color="secondary"
      />
    </AuthHeader>
    <AuthContent>
      <div class="auth-content__wrapper sign-in__wrapper">
        <h2 class="form_title">{{ $t('login.enter') }}</h2>
        <v-form ref="form" class="sign-in__form" v-model="isFormValid" lazy-validation @submit="onSubmit">
          <TextField
            :class="{ loading: submitInProgress }"
            class="sign-in__email"
            id="email"
            label="E-mail"
            autocomplete="on"
            name="email"
            v-model="email"
            :rules="[rules.required, rules.isEmail]"
            :disabled="submitInProgress"
          />
          <PasswordField
            :class="{ loading: submitInProgress }"
            class="sign-in__password"
            :label="$t('login.password')"
            v-model="password"
            :rules="[rules.required]"
            :disabled="submitInProgress"
          />
          <ActionView
            :class="{ loading: submitInProgress }"
            type="button"
            class="sign-in__forgot-password"
            @click="$router.push({ name: 'resetPassword' })"
          >
            {{ $t('login.forgetPassword') }}
          </ActionView>
          <!--          <CheckBox :disabled="submitInProgress" class="sing-in__remember">
                      {{ $t('login.remember') }}
                    </CheckBox>-->
          <AstroButton
            :class="{ load: submitInProgress }"
            class="sign-in__submit"
            type="submit"
            :title="$t('login.submit')"
            :disabled="!isFormValid"
            :loading="submitInProgress"
          />
          <div class="sign-in__mobile">
            <span>{{ $t('login.dontHaveAccount') }}</span>
            <AstroButton :title="$t('login.signUp')" @click="$router.replace({ name: 'signUp' })" color="secondary" />
          </div>
          <p class="sign-in__errors" v-if="error">
            {{ error }}
          </p>
        </v-form>
        <div class="sign-in__separator">{{ $t('or') }}</div>
        <google-authorization-link class="sign-in__google" :disabled="submitInProgress" />
      </div>
      <div class="auth-stars">
        <img class="auth-stars__img" src="../../assets/auth/stars.svg" alt="stars" />
      </div>
    </AuthContent>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import AuthHeader from '../components/AuthHeader';
import AuthContent from '../components/AuthContent';
import AstroButton from '../components/AstroButton';
import GoogleAuthorizationLink from '../components/GoogleAuthorizationLink';
import TextField from '../components/TextField';
import PasswordField from '../components/PasswordField';
import ActionView from '../atoms/ActionView';
import { UNDEFINED_ERROR_MESSAGE } from '@/constants';
/*import CheckBox from '@/elements/components/check-box/CheckBox';*/

export default {
  name: 'SignIn',
  components: {
    ActionView,
    PasswordField,
    TextField,
    AstroButton,
    GoogleAuthorizationLink,
    AuthHeader,
    AuthContent,
  },
  data() {
    return {
      isFormValid: true,
      submitInProgress: false,
      email: null,
      password: null,
      error: null,
      /*rules: {
        required: (value) =>
          !!value || (i18n.locale === 'ru' ? 'Поле должно быть заполнено' : 'Das Feld muss ausgefüllt werden'),
        isEmail: (value) => /.+@.+/.test(value) || 'Неверный формат E-mail',
      },*/
    };
  },
  computed: {
    ...mapGetters('UserModule', ['currentUser', 'carrotHash']),
    rules() {
      return {
        required: (value) =>
          !!value ||
          (this.$i18n.locale === 'en' ? 'This field should be filled in' : 'Das Feld muss ausgefüllt werden'),
        isEmail: (value) =>
          /.+@.+/.test(value) || (this.$i18n.locale === 'en' ? 'Invalid email format' : 'Ungültiges Email-Format'),
      };
    },
  },
  methods: {
    ...mapActions('UserModule', ['signIn', 'fetchUser']),
    async onSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.error = null;

      await this.$refs.form.validate();
      if (!this.isFormValid) {
        return;
      }
      this.submitInProgress = true;
      const signInData = {
        email: this.email,
        password: this.password,
      };
      try {
        await this.signIn(signInData);
        await this.fetchUser();
        await this.$router.push(this.$route.query.redirect || '/');
        if (this.currentUser.id && this.carrotHash) {
          // eslint-disable-next-line no-undef
          carrotquest.auth(this.currentUser.id.toString(), this.carrotHash);
        }
      } catch (error) {
        this.submitInProgress = false;
        if (error && error.status === 401) {
          if (this.$i18n.locale === 'en') {
            this.error = 'Wrong login or password';
          } else if (this.$i18n.locale === 'de') {
            this.error = 'Üngültige Anmeldedaten (E-Mail oder Passwort)';
          }
        } else {
          this.error = (error.data && error.data.message) || UNDEFINED_ERROR_MESSAGE;
        }
      }
    },
  },
};
</script>

<style scoped lang="sass">
.sign-in
  display: flex
  height: 100%
  flex-direction: column
  &__email::v-deep
    background-color: transparent
    input
      background: transparent
      width: 100%
      &:-webkit-autofill
        transition: background-color 600000s 0s, color 600000s 0s
      &:-webkit-autofill:focus
        transition: background-color 600000s 0s, color 600000s 0s
    &.loading
      fieldset
        background-color: grey-color('10')
        border: 1px solid grey-color('50')
    .error--text
      fieldset
        border: 1px solid #B00020
    .v-input__slot
      margin-bottom: base-unit(2)
      padding-left: base-unit(16) !important

  &__password::v-deep
    &.loading
      .v-icon
        display: none
      fieldset
        background-color: grey-color('10')
        border: 1px solid grey-color('50')
    .error--text
      fieldset
        border: 1px solid #B00020
    .v-input__slot
      margin-bottom: base-unit(2)
      padding-left: base-unit(16) !important
    .v-icon
      font-size: base-unit(16)
      color: grey-color('75')

  &__wrapper
    flex-direction: column
    border-radius: 4px
    padding: base-unit(30) base-unit(40) base-unit(40)
    border: 1px solid #F8F8F8
    +extra-small-devices
      padding: 0
      border: none
      width: 100%

  &__text
    margin-right: base-unit(10)
    color: grey-color('75')
    +base-font-size
    font-weight: 400
    @media screen and (max-width: base-unit(1024))
      display: none

  &__mobile
    display: flex
    flex-direction: column
    align-items: center
    margin-top: base-unit(10)

    @media screen and (min-width: base-unit(1024))
      display: none

    button
      border: none

  &__remember
    display: flex
    align-items: center

  &__form
    display: flex
    flex-direction: column
    width: base-unit(280)
    +extra-small-devices
      width: 100%

  &__password
    margin-top: base-unit(10)

  &__forgot-password
    margin-left: auto
    z-index: 1
    font-size: base-unit(16)
    line-height: base-unit(19)
    margin-top: base-unit(-5)
    color: blue-color('primary')
    &.loading
      color: grey-color('50')

    &:hover
      opacity: 0.8

  &__submit
    margin-top: base-unit(36)
    width: 100%
    &.load
      background-color: #73B2C7
      border: none

  &__register
    display: block
    +extra-small-devices
      font-size: base-unit(14)
      padding: 0 base-unit(15)
    @media screen and (max-width: base-unit(1024))
      display: none

  &__errors
    +extra-small-devices
      text-align: center
    +form-errors-text

  &__separator
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: base-unit(280)
    margin-top: base-unit(20)
    color: grey-color('500')
    +extra-small-devices
      margin: base-unit(20) auto

    &::before,
    &::after
      content: ''
      position: absolute
      width: base-unit(110)
      height: base-unit(1)
      background-color: grey-color('75')

    &::before
      left: 0

    &::after
      right: 0

  &__google
    margin-top: base-unit(20)
    +extra-small-devices
      margin: 0 auto
</style>
