<template>
  <div class="auth-content">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AuthContent',
};
</script>

<style scoped lang="sass">
.auth-content
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  height: 100%
  position: relative

  padding: base-unit(20)
  box-sizing: border-box
  +extra-small-devices
    padding: base-unit(10)
  &__wrapper
    display: flex
    flex-wrap: wrap
    justify-content: center
    .form_title
      margin-bottom: base-unit(30)
      color: grey-color('500')
      font-weight: 700
      font-size: base-unit(32)
      line-height: base-unit(38)
      +extra-small-devices
        display: none

.auth-stars
  position: absolute
  left: base-unit(20)
  bottom: base-unit(20)
  width: base-unit(207)
  height: base-unit(188)
  @media (max-width: $screen-md-min)
    display: none
  img
    width: 100%
    height: 100%
</style>
