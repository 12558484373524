<template>
  <v-app>
    <div class="auth-layout">
      <div class="auth-layout__body">
        <transition :name="transitionName" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
      <!--      <div class="auth-layout__background">
        <img class="auth-layout__planets" src="../../assets/auth/planets.svg" alt="planets" />
      </div>-->
    </div>
  </v-app>
</template>

<script>
const DEFAULT_TRANSITION = 'slide-left';

export default {
  name: 'AuthLayout',
  data() {
    return {
      transitionName: DEFAULT_TRANSITION,
    };
  },
  async beforeCreate() {
    if (this.$store.state.UserModule.loggedIn) {
      await this.$router.push({ name: 'root' });
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
};
</script>

<style scoped lang="sass">
$breakpoint: base-unit(1024)

.auth-layout
  display: flex
  height: 100vh
  @media (max-width: $screen-md-min)
    height: 100%

  &__body
    height: 100%
    flex-basis: 100%

    @media (max-width: $breakpoint)
      flex-basis: 100%

  &__background
    position: relative
    display: flex
    align-items: center
    justify-content: center
    flex-basis: 50%
    background: blue-color('primary')
    padding: base-unit(30)

    @media (max-width: $breakpoint)
      display: none

  &__stars
    position: absolute
    height: 100%
    width: 100%

  &__planets
    width: 30%
    max-width: base-unit(300)
    max-height: base-unit(850)
    height: 80%

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active
  transition-duration: 0.3s
  transition-property: height, opacity, transform
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1)
  overflow: hidden

.slide-left-enter,
.slide-right-leave-active
  opacity: 0
  transform: translateX(50%)


.slide-left-leave-active,
.slide-right-enter
  opacity: 0
  transform: translateX(-50%)
</style>
