<template>
  <TextField
    :type="isPasswordShow ? 'text' : 'password'"
    :append-icon="isPasswordShow ? 'visibility' : 'visibility_off'"
    @click:append="isPasswordShow = !isPasswordShow"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import TextField from './TextField';

export default {
  name: 'PasswordField',
  components: { TextField },
  data() {
    return {
      isPasswordShow: false,
    };
  },
};
</script>

<style lang="sass">
.v-icon
  .v-icon--link
    color: blue-color('primary')
</style>
