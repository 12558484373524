<template>
  <div class="confirm-email">
    <AuthHeader>
      <AstroButton v-if="isSuccess" :title="$t('login.enter')" @click="$router.replace({ name: 'signIn' })" />
    </AuthHeader>
    <div class="confirm-email__content" :class="{ 'ph-loading--content': isLoading }">
      <div class="confirm-email__banner">
        <template v-if="isSuccess">
          <img class="confirm-email__banner-success" src="@/assets/auth/success-banner-v2.svg" alt="banner" />
          <p class="confirm-email__banner-text">
            {{ $t('confirmEmail.confirmed') }}
          </p>
          <AstroButton :title="$t('login.enter')" @click="$router.replace({ name: 'signIn' })" />
        </template>
        <template v-else>
          <p class="confirm-email__banner-text">
            {{ $t('confirmEmail.error') }} <br />
            {{ $t('confirmEmail.support') }}
            <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import AuthHeader from '../components/AuthHeader';
import AstroButton from '../components/AstroButton';

const supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;

export default {
  name: 'ConfirmEmail',
  data() {
    return {
      isSuccess: false,
      isLoading: true,
      supportEmail,
    };
  },
  components: { AstroButton, AuthHeader },
  methods: {
    ...mapActions('UserModule', ['confirmEmail']),
  },
  async created() {
    this.$i18n.locale = this.$route.query.locale;
    try {
      await this.confirmEmail(this.$route.query.token);
      this.isSuccess = true;
    } catch {
      this.isSuccess = false;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped lang="sass">
.confirm-email
  display: flex
  flex-direction: column

  &__content
    display: flex
    flex-direction: column
    align-items: center

  &__banner
    display: flex
    flex-direction: column
    width: 100%
    align-items: center
    margin-top: 5vh
    button
      display: none
      +extra-small-devices
        width: 100%
        display: flex

  &__banner-success
    width: 30%
    margin-left: 1%
    max-width: base-unit(300)

  &__banner-text
    margin-top: base-unit(30)
    text-align: center
    font-size: base-unit(18)
    width: 60%
    font-weight: 400
    color: grey-color('500')
</style>
